import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const AiPromptsAiAndTheSeasons = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Using AI Storytelling
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Use AI to generate engaging, age-appropriate stories to explain seasonal changes.
                    </Typography>
                </Box>

                {/* Steps for AI Storytelling */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Steps
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Open ChatGPT or another AI tool. You can also use the AIDEN curriculum designer."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Input prompts like:"
                                secondary="'Write a short story about a squirrel preparing for winter.' or 'Tell a story about a flower blooming in spring and a bee visiting it.'"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Review the response to ensure it matches your lesson focus."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Read the story aloud to the class, pausing for questions or to show related images."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Enhancement Ideas Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Enhancement Ideas
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Ask the AI to adjust the tone or vocabulary if the story feels too advanced."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Use AI to generate follow-up questions like:"
                                secondary="'What might happen if the squirrel didn’t gather enough food?'"
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Creating Seasonal Visuals Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Creating Seasonal Visuals
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Use AI to generate simple images that illustrate seasonal changes (if other visuals aren’t readily available).
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Use a tool like DALL-E or Canva to create:"
                                secondary="A tree across the four seasons or an animal experiencing seasonal changes (e.g., a bear in winter, spring, summer, and autumn)."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Print these images or display them in the classroom for activities like matching changes to seasons."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Seasonal Questions Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Using AI for Seasonal Questions
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Use AI to create discussion prompts and hypothetical scenarios.
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Input prompts into ChatGPT such as:"
                                secondary="'What questions could I ask children to help them understand why trees lose their leaves in autumn?' or 'Create a scenario where spring never arrives—what might happen to plants and animals?'"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Write down or print AI-generated questions for classroom discussions."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Additional Enhancement Ideas Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Additional Enhancement Ideas
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Use AI-generated scenarios as the basis for drawing or storytelling activities."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Ask students to create their own stories or explanations based on the questions."
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsAiAndTheSeasons;
