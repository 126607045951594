import LightbulbIcon from '@mui/icons-material/Lightbulb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';

const MetacognitiveExerciseAiWeatherDetectives = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Activity Title: "How Do We Predict the Weather?"
                </Typography>

                {/* Group Discussion Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Instructions for Students
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Group Discussion (Teacher-Led)"
                                secondary="Reflect on the AI’s role in understanding weather with these prompts: 
                                'How did AI help us understand the weather?' 
                                'What was different between our predictions and AI’s answers?'"
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Reflection Worksheet Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Reflection Worksheet
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table aria-label="reflection worksheet">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Section</TableCell>
                                    <TableCell>Prompt</TableCell>
                                    <TableCell>Student Task</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>AI in Our Lesson</TableCell>
                                    <TableCell>"What did the AI tell us about tomorrow’s weather?"</TableCell>
                                    <TableCell>Write or draw one thing AI predicted and whether you agree with it.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Patterns in Weather</TableCell>
                                    <TableCell>"What weather pattern did you notice this week?"</TableCell>
                                    <TableCell>Draw the pattern (e.g., rain every afternoon) and explain what caused it.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wondering with AI</TableCell>
                                    <TableCell>"What new question would you ask AI about weather?"</TableCell>
                                    <TableCell>Write or draw a question, such as "Why do storms happen in spring?".</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Extension Activity Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Optional Extension Activity: AI-Assisted Brainstorm
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Objective: Engage with AI creatively by brainstorming new uses for it."
                                secondary="Ask students how they might want to use AI in future lessons: 
                                'What else could the AI help us learn about?' 
                                'Could AI tell us a story about animals in a season we haven’t talked about yet?'"
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <QuestionAnswerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Teacher’s Role"
                                secondary="Use students' ideas to generate a short new AI story or image during the next lesson."
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default MetacognitiveExerciseAiWeatherDetectives;
