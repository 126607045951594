import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import QuestionMark from '@mui/icons-material/QuestionMark';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useParams } from "react-router-dom";

const AssessmentToolkitAiAndTheSeasons = () => {
    const { className } = useParams();

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Rubric Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        1. Rubric for AI-Enhanced Storytelling
                    </Typography>
                    
                    {/* Creative Use of AI */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Creative Use of AI
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Evaluates how well students use AI suggestions to brainstorm and develop story elements, showing an ability to guide AI effectively while making the story their own.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>

                    {/* Personalization */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Personalization of AI-Generated Content
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Assesses the student's skill in modifying or building on AI-generated suggestions, demonstrating creativity and ownership over the final story.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>

                    {/* Story Structure */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Story Structure with AI Support
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Measures the coherence of the story's structure (beginning, middle, end), particularly noting how the student uses AI prompts to create smooth transitions or resolve the story.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>

                    {/* Character Development */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Character Development Using AI
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Evaluates the depth and consistency of characters created, emphasizing how students used AI to generate or refine character traits and motivations.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>
                </Box>

                {/* Quick Rubric Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        2. Quick Rubric for Teacher Evaluation
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="quick rubric">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Criteria</TableCell>
                                    <TableCell align="center">1 - Needs Improvement</TableCell>
                                    <TableCell align="center">3 - Satisfactory</TableCell>
                                    <TableCell align="center">5 - Excellent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Understanding Seasons</TableCell>
                                    <TableCell align="center">Struggles to identify seasonal changes.</TableCell>
                                    <TableCell align="center">Recognizes basic changes.</TableCell>
                                    <TableCell align="center">Explains changes across seasons with detail.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Engagement with AI</TableCell>
                                    <TableCell align="center">Minimal engagement with AI-generated content.</TableCell>
                                    <TableCell align="center">Interacts with AI but lacks depth.</TableCell>
                                    <TableCell align="center">Actively engages, asking questions or offering insights.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Participation</TableCell>
                                    <TableCell align="center">Rarely contributes to group discussions.</TableCell>
                                    <TableCell align="center">Occasionally contributes.</TableCell>
                                    <TableCell align="center">Actively participates and collaborates effectively.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Reflection Worksheet Review */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        3. Reflection Worksheet Review
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="reflection worksheet">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Section</TableCell>
                                    <TableCell>Evaluation Criteria</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>AI in Our Lesson</TableCell>
                                    <TableCell>Student describes what they learned from AI outputs.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Visual Learning</TableCell>
                                    <TableCell>Student explains how AI images helped them understand seasons.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wondering with AI</TableCell>
                                    <TableCell>Student formulates an interesting or relevant question.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Student Checklist Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        4. AI Reflection Checklist for Students
                    </Typography>
                    <List>
                        {["Did I use AI to brainstorm ideas for my story?",
                          "How did I change the AI's suggestions to make the story unique?",
                          "Did the AI help me think of new plot twists or character details?",
                          "How did I decide what to keep or change from the AI suggestions?"]
                          .map((question, index) => (
                            <ListItem key={index} sx={{ pl: 2 }}>
                                <ListItemIcon>
                                    <CheckCircleOutline color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={question} />
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Peer Feedback Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        5. Peer Feedback with AI Focus
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText 
                                primary="Creative AI Use"
                                secondary="What did you find interesting about how they used AI in the story?"
                                primaryTypographyProps={{ color: 'primary', fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText 
                                primary="Suggestions for Personalization"
                                secondary="What's one idea to make the story feel more like their own?"
                                primaryTypographyProps={{ color: 'primary', fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText 
                                primary="Favorite AI-Generated Element"
                                secondary="What was your favorite character or scene, and why?"
                                primaryTypographyProps={{ color: 'primary', fontWeight: 'medium' }}
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Teacher Feedback Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        6. Teacher Feedback Guide
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Encouragement and Constructive Feedback
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                            Provide feedback on the student's engagement with AI, noting both effective uses and opportunities for further exploration.
                        </Typography>

                        <Typography variant="h6" color="primary" gutterBottom>
                            Balanced AI Use
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                            Comment on the balance between AI-generated and student-created content, encouraging deeper personalization of AI prompts.
                        </Typography>
                    </Box>
                </Box>

                {/* Reflective Questions Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        7. Reflective Questions on AI's Role
                    </Typography>
                    <List>
                        {["How did using AI change the way you approached storytelling?",
                          "What were some of the best ideas the AI helped you come up with?",
                          "What would you do differently next time when using AI for writing?"]
                          .map((question, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <QuestionMark color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={question} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AssessmentToolkitAiAndTheSeasons;
