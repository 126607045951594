import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import TimerIcon from '@mui/icons-material/Timer';
import TargetIcon from '@mui/icons-material/TrackChanges';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

const LessonPlanObjectivesAIAndSeasons = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Main Title and Session Objectives */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h3" color="primary" gutterBottom>
                        Lesson Plan: AI and The Seasons
                    </Typography>

                    <Typography variant="h4" color="primary" sx={{ mt: 4, mb: 2 }}>
                        Session Objectives
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Observe Seasonal Changes"
                                secondary="Students will identify how weather and nature change across seasons."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SchoolIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Understand Cause and Effect"
                                secondary="Students will connect seasonal changes to their effects on plants, animals, and humans."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Creative Engagement with AI"
                                secondary="Teachers will use AI tools to generate seasonal stories, images, or discussion prompts to enhance learning."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Learning Lab Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 1: Learning Lab
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Introduction to Seasons (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher introduces the four seasons using visuals generated with an AI tool like DALL-E (via OpenAI) or Canva."
                                    secondary="Prompt for DALL-E: 'Create four simple illustrations of the same tree in spring, summer, autumn, and winter.'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Print or project the images in the classroom."
                                    secondary="Compare AI-generated outputs with real-life examples and discuss visible differences."
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Seasonal Storytelling with AI (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="The teacher uses ChatGPT to generate a story about a character experiencing seasonal changes."
                                    secondary="Prompts: 'Write a story about a squirrel preparing for winter in simple language suitable for 6-year-olds.'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher reads the AI-generated story aloud, pausing to engage students."
                                    secondary="Questions: 'Why is the squirrel gathering nuts?' or 'What do you think happens to the flower in summer?'"
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Practice Pad Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 2: Practice Pad
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Matching Activity: Seasons and Changes (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Students work in small groups to match pictures of seasonal changes with the correct season."
                                    secondary="Use DALL-E to generate additional custom images: 'Draw simple illustrations of falling leaves in autumn, snow in winter, blooming flowers in spring, and sunshine in summer.'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Discussion prompts:"
                                    secondary="'Why do leaves fall in autumn?' or 'What does the snow mean for animals in winter?'"
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Class Reflection: How Do Seasons Affect Us? (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Class discusses how each season affects their daily lives."
                                    secondary="Questions: 'What do you wear in winter?' or 'What games do you play in summer?'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher uses ChatGPT to generate additional discussion prompts."
                                    secondary="Example: 'What foods do you eat in summer vs. winter?' or 'How does your family celebrate the seasons?'"
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Resources Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Resources Required
                    </Typography>

                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Laptop or Tablet"
                                secondary="Each student or pair should have access to a device with internet for AI-based brainstorming and storytelling."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Whiteboard and Markers"
                                secondary="For brainstorming and displaying examples."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Seasonal Images or Cards" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Drawing Materials for Reflections" />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default LessonPlanObjectivesAIAndSeasons;
