import ArticleIcon from '@mui/icons-material/Article';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PeopleIcon from '@mui/icons-material/People';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const MetacognitiveExerciseAnimalAdventuresWithAI = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Activity Title: "How Do Animals and AI Help Us Learn?"
                </Typography>

                {/* Group Discussion Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Instructions for Students
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Group Discussion (Teacher-Led)"
                                secondary="Reflect on how AI helped during the lesson: 'What did you learn from the AI’s story?' and 'How did the AI answer our questions about animals?'"
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Reflection Worksheet Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Reflection Worksheet
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <ArticleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="AI in Our Lesson"
                                secondary="Prompt: 'What story did the AI tell us about animals?' Students draw or write about the AI story (e.g., a hedgehog gathering food)."
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Animal Needs"
                                secondary="Prompt: 'What does your favorite animal need to live?' Students draw the animal and write one thing it needs (e.g., water or food)."
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <QuestionAnswerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Wondering with AI"
                                secondary="Prompt: 'What question would you ask AI about animals?' Students write or draw a question for AI, such as 'What do foxes eat in winter?'"
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default MetacognitiveExerciseAnimalAdventuresWithAI;
