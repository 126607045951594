import LightbulbIcon from '@mui/icons-material/Lightbulb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';

const MetacognitiveExerciseAiAndTheSeasons = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Activity Title: "How Did AI Help Us Learn About the Seasons?"
                </Typography>

                {/* Group Discussion Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Instructions for Students
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Group Discussion (Teacher-Led)"
                                secondary="Reflect on how AI was used during the lesson with these prompts: 'What did you learn from the story about the squirrel (or flower) that the AI helped create?' 'How did the pictures from AI help you understand the seasons?' 'What was your favorite thing AI showed or told us today?'"
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Reflection Worksheet Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Individual Reflection Worksheet
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table aria-label="reflection worksheet">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Section</TableCell>
                                    <TableCell>Prompt</TableCell>
                                    <TableCell>Student Task</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>AI in Our Lesson</TableCell>
                                    <TableCell>"What story did the AI tell us about the seasons? What did you like about it?"</TableCell>
                                    <TableCell>Draw or write about the AI story (e.g., the squirrel gathering nuts or the flower blooming).</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Visual Learning</TableCell>
                                    <TableCell>"What picture did we see from the AI, and how did it help you understand the seasons?"</TableCell>
                                    <TableCell>Draw the AI-generated picture (e.g., a tree in autumn) and write what you learned from it.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wondering with AI</TableCell>
                                    <TableCell>"What would you like to ask the AI about seasons?"</TableCell>
                                    <TableCell>Write or draw a question to ask AI next time (e.g., "What happens to flowers in winter?").</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Extension Activity Section */}
                    <Typography variant="h5" color="primary" gutterBottom>
                        Optional Extension Activity: AI-Assisted Brainstorm
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Objective: Engage with AI creatively by brainstorming new uses for it."
                                secondary="Ask students how they might want to use AI in future lessons: 'What else could the AI help us learn about?' or 'Could AI tell us a story about animals in a season we haven’t talked about yet?'"
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <QuestionAnswerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Teacher’s Role"
                                secondary="Use students' ideas to generate a short new AI story or image during the next lesson."
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default MetacognitiveExerciseAiAndTheSeasons;
