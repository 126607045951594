import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

const AssessmentToolkitAiWeatherDetectives = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Assessment Toolkit
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These evaluation rubrics and self-assessment tools encourage meaningful engagement with AI-generated content and weather observation activities.
                    </Typography>
                </Box>

                {/* Quick Rubric for Teacher Evaluation */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Quick Rubric for Teacher Evaluation
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table aria-label="quick rubric">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Criteria</TableCell>
                                    <TableCell align="center">1 - Needs Improvement</TableCell>
                                    <TableCell align="center">3 - Satisfactory</TableCell>
                                    <TableCell align="center">5 - Excellent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Data Collection</TableCell>
                                    <TableCell align="center">Struggles to record weather observations.</TableCell>
                                    <TableCell align="center">Records some observations but lacks detail.</TableCell>
                                    <TableCell align="center">Records detailed weather data with accuracy.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>AI Interaction</TableCell>
                                    <TableCell align="center">Minimal engagement with AI tools.</TableCell>
                                    <TableCell align="center">Participates but without much depth.</TableCell>
                                    <TableCell align="center">Actively engages, asking insightful questions or refining prompts.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Visual Presentation</TableCell>
                                    <TableCell align="center">Struggles to create clear charts.</TableCell>
                                    <TableCell align="center">Creates basic charts with some errors.</TableCell>
                                    <TableCell align="center">Designs accurate and creative charts that showcase data effectively.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Quick Self-Assessment for Students */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Quick Self-Assessment for Students
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Data Skills"
                                secondary="Did I collect weather data accurately?"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="AI Insights"
                                secondary="What did I learn from the AI about weather predictions?"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Participation"
                                secondary="Did I share ideas or ask questions during class activities?"
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AssessmentToolkitAiWeatherDetectives;
