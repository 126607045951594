import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import TimerIcon from '@mui/icons-material/Timer';
import TargetIcon from '@mui/icons-material/TrackChanges';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const LessonPlanObjectivesAiWeatherDetectives = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Main Title and National Curriculum Alignment */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h3" color="primary" gutterBottom>
                        Lesson Plan: AI and The Seasons
                    </Typography>

                    <Typography variant="h4" color="primary" sx={{ mt: 4, mb: 2 }}>
                        National Curriculum Alignment
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Observe and describe weather associated with the seasons and how day length varies."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Use straightforward scientific evidence to answer questions or to support findings."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Gather, record, classify, and present data in a variety of ways to help in answering questions."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Session Objectives */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Session Objectives
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Collect and Analyze Weather Data"
                                secondary="Students will observe, record, and interpret local weather patterns."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SchoolIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Predict Weather Using AI"
                                secondary="Students will use AI tools to analyze data and predict future weather trends."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Visualize Weather Patterns"
                                secondary="Create weather-related charts and graphics using Canva."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Learning Lab Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 1: Learning Lab
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Observing Weather Patterns (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher introduces weather observation tools (e.g., thermometer, rain gauge, or weather app)."
                                    secondary="Students go outside (if feasible) to record the current weather or use a teacher-provided weather summary."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Discuss the findings:"
                                    secondary="'What do you notice about today’s weather?' and 'How might the weather change tomorrow?'"
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Using AI to Analyze Weather Data (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher-Guided AI Use"
                                    secondary="Open GPT (shared on-screen) and input prompts based on recorded data like 'Based on today’s temperature and wind speed, what might the weather be like tomorrow?'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Students help refine prompts and interpret AI responses."
                                    secondary="Discuss whether AI agrees with predictions and its evidence."
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Practice Pad Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 2: Practice Pad
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Creating Weather Charts (20 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Students use Canva to design weather charts based on collected data."
                                    secondary="Examples include bar graphs for rainfall, line graphs for temperature changes, and infographics for typical weather in different months."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="If Canva is unavailable, students create paper-based charts."
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Class Reflection: Predicting the Future (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="AI-Powered Exploration"
                                    secondary="Students brainstorm prompts like 'What will happen if it rains every day for a month?' or 'How does weather affect what animals do?'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher inputs the best prompts into GPT and shares responses."
                                    secondary="Encourage group discussions on the insights provided."
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Resources Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Resources Required
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Laptop or Tablet"
                                secondary="Each student or pair should have access to a device with internet for AI-based brainstorming and storytelling."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Whiteboard and Markers"
                                secondary="For brainstorming and displaying examples."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Seasonal Images or Cards" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Drawing Materials for Reflections" />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default LessonPlanObjectivesAiWeatherDetectives;
