import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import TimerIcon from '@mui/icons-material/Timer';
import TargetIcon from '@mui/icons-material/TrackChanges';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

const LessonPlanObjectivesAnimalAdventuresWithAI = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Main Title and Session Objectives */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h3" color="primary" gutterBottom>
                        Lesson Plan: Animal Adventures with AI
                    </Typography>

                    <Typography variant="h4" color="primary" sx={{ mt: 4, mb: 2 }}>
                        Session Objectives
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Understand Animal Needs"
                                secondary="Students will learn what animals need to survive (food, water, and shelter)."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SchoolIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Recognize Animal Habitats"
                                secondary="Identify where different animals live and why those habitats meet their needs."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Creative Engagement with AI"
                                secondary="Teachers will use AI to generate animal stories and prompts that spark curiosity."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Learning Lab Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 1: Learning Lab
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Introduction to Animal Needs (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher introduces the concept of animal needs (food, water, shelter) with Canva-generated visuals."
                                    secondary="Project or print images of animals (e.g., a bird drinking water, a fox in a den) and discuss their needs."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Show visuals and ask questions like:"
                                    secondary="'What does this animal need to live?' and 'Where does it find water or food?'"
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Co-Creating Prompts for AI Stories (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher explains how GPT can help tell animal stories."
                                    secondary="Students brainstorm ideas for story prompts, such as 'A fox trying to find food in winter.'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher selects one or two student-generated ideas and enters them into GPT."
                                    secondary="Reads the AI-generated story aloud, pausing for student input: 'What might happen next?'"
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Practice Pad Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 2: Practice Pad
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Matching Game: Animals and Their Needs (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher creates animal and habitat cards using Canva (e.g., 'Bird – Nest,' 'Fish – Pond')."
                                    secondary="Students work in small groups to match animals with their needs."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Discussion prompts:"
                                    secondary="'Why does a bird need a nest?' and 'What happens if a fish doesn’t find clean water?'"
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Class Reflection: Imagining New Scenarios with AI (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Students think of hypothetical questions, such as:"
                                    secondary="'What would happen if a fox couldn’t find a den in winter?'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher inputs a question into GPT and shares the AI’s response with the class."
                                    secondary="Students draw or explain their interpretation of the response."
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Resources Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Resources Required
                    </Typography>

                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Laptop or Tablet"
                                secondary="Each student or pair should have access to a device with internet for AI-based brainstorming and storytelling."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Whiteboard and Markers"
                                secondary="For brainstorming and displaying examples."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Animal and Habitat Cards" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Drawing Materials for Reflections" />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default LessonPlanObjectivesAnimalAdventuresWithAI;
