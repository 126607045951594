import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const AiPromptsAndActivities = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        AI Prompts and Activities
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Teachers can use these master prompts with GPT to create engaging content and inspire creativity in the classroom.
                    </Typography>
                </Box>

                {/* Animal Stories Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Animal Stories
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Write a story about a hedgehog gathering food for winter in language suitable for 6-year-olds." 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Tell a story about a bird building a nest in spring and what it does to protect its chicks." 
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Discussion Scenarios Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Discussion Scenarios
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="What happens if a fox doesn’t find a den before winter?" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Describe how a frog survives in its pond during summer." 
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Creative Thinking Prompts Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Creative Thinking Prompts
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Explain what would happen if all the ponds in the forest dried up. How would animals adapt?" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="What new habitat might a bird create if there were no trees?" 
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsAndActivities;
