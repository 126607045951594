import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

const AiPromptsAiWeatherDetectives = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        AI Prompts and Classroom Activities
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These prompts and activities encourage students to interact meaningfully with AI-generated content while aligning with lesson objectives.
                    </Typography>
                </Box>

                {/* Advanced Data Analysis Prompts */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Advanced Data Analysis Prompts
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Weather Trends"
                                secondary="'Based on historical weather data, explain how temperature and rainfall typically vary throughout [month/season] in [region].'"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Weather Predictions"
                                secondary="'If today’s temperature is [X] and the wind speed is [Y], what are the most likely weather conditions tomorrow? Explain your reasoning.'"
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Creative and Hypothetical Prompts */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Creative and Hypothetical Prompts
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Weather Scenarios"
                                secondary="'What would happen if it rained every single day for a year? How would this affect the environment, humans, and animals?'"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Climate Extremes"
                                secondary="'What if winter lasted for six months? How would this affect the food we eat and the activities we do?'"
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* AI-Driven Classroom Activities */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        AI-Driven Classroom Activities
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Activity 1: Build a Weekly Weather Report"
                                secondary="Students input daily weather data into AI and create a collaborative 5-day weather report, including predictions and environmental impacts."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Activity 2: Analyze Historical Weather Patterns"
                                secondary="Students use AI to explore trends and create presentations explaining changes over time."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Activity 3: AI-Generated Weather Scenarios"
                                secondary="Students brainstorm extreme weather scenarios, input them into AI, and discuss impacts on plants, animals, and humans."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Reflection Worksheet Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Reflection Worksheet: Connecting AI and Seasonal Learning
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table aria-label="reflection worksheet">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Section</TableCell>
                                    <TableCell>Prompt</TableCell>
                                    <TableCell>Student Task</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>AI in Our Lesson</TableCell>
                                    <TableCell>"What story did the AI tell us about the seasons? What did you like about it?"</TableCell>
                                    <TableCell>Draw or write about the AI story (e.g., the squirrel gathering nuts or the flower blooming).</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Visual Learning</TableCell>
                                    <TableCell>"What picture did we see from the AI, and how did it help you understand the seasons?"</TableCell>
                                    <TableCell>Draw the AI-generated picture (e.g., a tree in autumn) and write what you learned from it.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wondering with AI</TableCell>
                                    <TableCell>"What would you like to ask the AI about seasons?"</TableCell>
                                    <TableCell>Write or draw a question to ask AI next time (e.g., "What happens to flowers in winter?").</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsAiWeatherDetectives;
