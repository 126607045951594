import AiPromptsStoryTimeExplorers from '../pages/resource-hub/English/keyStage1/storyTimeExplorers/AiPrompts';
import AssessmentToolkitStoryTimeExplorers from '../pages/resource-hub/English/keyStage1/storyTimeExplorers/AssessmentToolkit';
import LessonPlanObjectivesStoryTimeExplorers from '../pages/resource-hub/English/keyStage1/storyTimeExplorers/LessonPlanObjectives';
import MetacognitiveExerciseStoryTimeExplorers from '../pages/resource-hub/English/keyStage1/storyTimeExplorers/MetacognitiveExercise';
import AiPromptsWordsInAction from '../pages/resource-hub/English/keyStage1/wordsInAction/AiPrompts';
import AssessmentToolkitWordsInAction from '../pages/resource-hub/English/keyStage1/wordsInAction/AssessmentToolkit';
import LessonPlanObjectivesWordsInAction from '../pages/resource-hub/English/keyStage1/wordsInAction/LessonPlanObjectives';
import MetacognitiveExerciseWordsInAction from '../pages/resource-hub/English/keyStage1/wordsInAction/MetacognitiveExercise';
import AiPromptsAiAssistedCreativeWriting from '../pages/resource-hub/English/keyStage2/aIAssistedCreativeWriting/AiPrompts';
import AssessmentToolkitAiAssistedCreativeWriting from '../pages/resource-hub/English/keyStage2/aIAssistedCreativeWriting/AssessmentToolkit';
import LessonPlanObjectivesAiAssistedCreativeWriting from '../pages/resource-hub/English/keyStage2/aIAssistedCreativeWriting/LessonPlanObjectives';
import MetacognitiveExerciseAiAssistedCreativeWriting from '../pages/resource-hub/English/keyStage2/aIAssistedCreativeWriting/MetacognitiveExercise';
import AiPromptsPoetryWithAI from '../pages/resource-hub/English/keyStage2/poetryWithAi/AiPrompts';
import AssessmentToolkitPoetryWithAI from '../pages/resource-hub/English/keyStage2/poetryWithAi/AssessmentToolkit';
import LessonPlanObjectivesPoetryWithAI from '../pages/resource-hub/English/keyStage2/poetryWithAi/LessonPlanObjectives';
import MetacognitiveExercisePoetryWithAI from '../pages/resource-hub/English/keyStage2/poetryWithAi/MetacognitiveExercise';
import AiPromptsAiAssistedLiteraryAnalysis from '../pages/resource-hub/English/keyStage3/aiAssistedLiteraryAnalysis/AiPrompts';
import AssessmentToolkitAiAssistedLiteraryAnalysis from '../pages/resource-hub/English/keyStage3/aiAssistedLiteraryAnalysis/AssessmentToolkit';
import LessonPlanObjectivesAiAssistedLiteraryAnalysis from '../pages/resource-hub/English/keyStage3/aiAssistedLiteraryAnalysis/LessonPlanObjectives';
import MetacognitiveExerciseAiAssistedLiteraryAnalysis from '../pages/resource-hub/English/keyStage3/aiAssistedLiteraryAnalysis/MetacognitiveExercise';
import AiPromptsAiAssistedHistoricalTrendsCausesAnalysis from '../pages/resource-hub/History/keyStage3/aiAssistedHistoricalTrendsCausesAnalysis/AiPrompts';
import AssessmentToolkitAiAssistedHistoricalTrendsCausesAnalysis from '../pages/resource-hub/History/keyStage3/aiAssistedHistoricalTrendsCausesAnalysis/AssessmentToolkit';
import LessonPlanObjectivesAiAssistedHistoricalTrendsCausesAnalysis from '../pages/resource-hub/History/keyStage3/aiAssistedHistoricalTrendsCausesAnalysis/LessonPlanObjectives';
import MetacognitiveExerciseAiAssistedHistoricalTrendsCausesAnalysis from '../pages/resource-hub/History/keyStage3/aiAssistedHistoricalTrendsCausesAnalysis/MetacognitiveExercise'; 

import AiPromptsAnimalAdventuresWithAI from '../pages/resource-hub/Science/keyStage1/animalAdventuresWithAI/AiPrompts';
import AssessmentToolkitAnimalAdventuresWithAI from '../pages/resource-hub/Science/keyStage1/animalAdventuresWithAI/AssessmentToolkit';
import LessonPlanObjectivesAnimalAdventuresWithAI from '../pages/resource-hub/Science/keyStage1/animalAdventuresWithAI/LessonPlanObjectives';
import MetacognitiveExerciseAnimalAdventuresWithAI from '../pages/resource-hub/Science/keyStage1/animalAdventuresWithAI/MetacognitiveExercise';

import AiPromptsAiAndTheSeasons from '../pages/resource-hub/Science/keyStage1/aiAndTheSeasons/AiPrompts';
import AssessmentToolkitAiAndTheSeasons from '../pages/resource-hub/Science/keyStage1/aiAndTheSeasons/AssessmentToolkit';
import LessonPlanObjectivesAiAndTheSeasons from '../pages/resource-hub/Science/keyStage1/aiAndTheSeasons/LessonPlanObjectives';
import MetacognitiveExerciseAiAndTheSeasons from '../pages/resource-hub/Science/keyStage1/aiAndTheSeasons/MetacognitiveExercise';

import AiPromptsAiWeatherDetectives from '../pages/resource-hub/Science/keyStage2/aiWeatherDetectives/AiPrompts';
import AssessmentToolkitAiWeatherDetectives from '../pages/resource-hub/Science/keyStage2/aiWeatherDetectives/AssessmentToolkit';
import LessonPlanObjectivesAiWeatherDetectives from '../pages/resource-hub/Science/keyStage2/aiWeatherDetectives/LessonPlanObjectives';
import MetacognitiveExerciseAiWeatherDetectives from '../pages/resource-hub/Science/keyStage2/aiWeatherDetectives/MetacognitiveExercise';

export const enums = {
    MENTOR: "MENTOR",
    INTERN: "INTERN",
    ADMIN: "ADMIN",
    TEACHER: "TEACHER",
    USER: "USER",
    STUDENT: "STUDENT",
}

export const classes = [
    { name: "English", available: true },
    { name: "Science", available: true },
    { name: "History", available: true },
    { name: "Computer Science", available: true },
    { name: "Drama", available: false },
    { name: "Math", available: false },
    { name: "Art", available: false },
    { name: "Physical Education", available: false },
    { name: "Music", available: false },
    { name: "Technology", available: false },
    { name: "Coding & Robotics", available: false }
];
export const grades = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];


export const modules = [
    {
        class: "English",
        keyStage: "ks1",
        title: 'Story Time Explorers',
        
        module: "storyTimeExplorers",
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsStoryTimeExplorers'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitStoryTimeExplorers'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesStoryTimeExplorers'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseStoryTimeExplorers'
            }
        ]
    },
    {
        class: "English",
        keyStage: 'ks1',
        title: 'Words in Action',
        
        module: 'wordsInAction',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsWordsInAction'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitWordsInAction'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesWordsInAction'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseWordsInAction'
            }
        ]
    },
    {
        class: "English",
        keyStage: 'ks2',
        title: 'Poetry with AI',
        module: 'poetryWithAI',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsPoetryWithAI'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitPoetryWithAI'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesPoetryWithAI'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExercisePoetryWithAI'
            }
        ]
    }, {
        class: "English",
        keyStage: 'ks2',
        title: 'AI assisted creative writing',
        
        module: 'aiAssistedCreativeWriting',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsAiAssistedCreativeWriting'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitAiAssistedCreativeWriting'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesAiAssistedCreativeWriting'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseAiAssistedCreativeWriting'
            }
        ]
    },{
        class: "English",
        keyStage: 'ks3',
        title: 'AI Assisted Literary Analysis',
        
        module: 'aiAssistedLiteraryAnalysis',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsAiAssistedLiteraryAnalysis'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitAiAssistedLiteraryAnalysis'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesAiAssistedLiteraryAnalysis'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseAiAssistedLiteraryAnalysis'
            }
        ]
    },
    {
        class: "History",
        keyStage: 'ks3',
        title: 'AI Assisted Historical Trends and Causes Analysis',
        
        module: 'aiAssistedHistoricalTrendsCausesAnalysis',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsAiAssistedHistoricalTrendsCausesAnalysis'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitAiAssistedHistoricalTrendsCausesAnalysis'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesAiAssistedHistoricalTrendsCausesAnalysis'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseAiAssistedHistoricalTrendsCausesAnalysis'
            }
        ]
    }, {
        class: "Science",
        keyStage: 'ks1',
        title: 'Animal Adventures with AI',
        
        module: 'animalAdventuresWithAI',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsAnimalAdventuresWithAI'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitAnimalAdventuresWithAI'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesAnimalAdventuresWithAI'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseAnimalAdventuresWithAI'
            }
        ]
    }, {
        class: "Science",
        keyStage: 'ks1',
        title: 'Ai and the Seasons',
        
        module: 'aiAndTheSeasons',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsAiAndTheSeasons'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitAiAndTheSeasons'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesAiAndTheSeasons'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseAiAndTheSeasons'
            }
        ]
    }, {
        class: "Science",
        keyStage: 'ks2',
        title: 'Ai Weather Detectives',
        
        module: 'aiWeatherDetectives',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsAiWeatherDetectives'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitAiWeatherDetectives'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesAiWeatherDetectives'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseAiWeatherDetectives'
            }
        ]
    }, {
        class: "Science",
        keyStage: 'ks2',
        title: 'Exploring Habitats with AI',
        
        module: 'exploringHabitatsWithAi',
        components: [
            {
                title: 'AI Prompts',
                componentName: 'AiPromptsExploringHabitatsWithAi'
            },
            {
                title: 'Assessment Toolkit',
                componentName: 'AssessmentToolkitExploringHabitatsWithAi'
            },
            {
                title: 'Lesson Plan & Objectives',
                componentName: 'LessonPlanObjectivesExploringHabitatsWithAi'
            },
            {
                title: 'Metacognitive Exercise',
                componentName: 'MetacognitiveExerciseExploringHabitatsWithAi'
            }
        ]
    }
]

export const componentMapping = {
    AiPromptsStoryTimeExplorers,
    AssessmentToolkitStoryTimeExplorers,
    LessonPlanObjectivesStoryTimeExplorers,
    MetacognitiveExerciseStoryTimeExplorers,
    AiPromptsWordsInAction,
    AssessmentToolkitWordsInAction,
    LessonPlanObjectivesWordsInAction,
    MetacognitiveExerciseWordsInAction,
    AiPromptsAiAssistedCreativeWriting,
    AssessmentToolkitAiAssistedCreativeWriting,
    LessonPlanObjectivesAiAssistedCreativeWriting,
    MetacognitiveExerciseAiAssistedCreativeWriting,
    AiPromptsPoetryWithAI,
    AssessmentToolkitPoetryWithAI,
    LessonPlanObjectivesPoetryWithAI,
    MetacognitiveExercisePoetryWithAI,
    AiPromptsAiAssistedLiteraryAnalysis,
    AssessmentToolkitAiAssistedLiteraryAnalysis,
    LessonPlanObjectivesAiAssistedLiteraryAnalysis,
    MetacognitiveExerciseAiAssistedLiteraryAnalysis,
    AiPromptsAiAssistedHistoricalTrendsCausesAnalysis,
    AssessmentToolkitAiAssistedHistoricalTrendsCausesAnalysis,
    LessonPlanObjectivesAiAssistedHistoricalTrendsCausesAnalysis,
    MetacognitiveExerciseAiAssistedHistoricalTrendsCausesAnalysis,
    AiPromptsAnimalAdventuresWithAI,
    AssessmentToolkitAnimalAdventuresWithAI,
    LessonPlanObjectivesAnimalAdventuresWithAI,
    MetacognitiveExerciseAnimalAdventuresWithAI,
    AiPromptsAiAndTheSeasons,
    AssessmentToolkitAiAndTheSeasons,
    LessonPlanObjectivesAiAndTheSeasons,
    MetacognitiveExerciseAiAndTheSeasons,
    AiPromptsAiWeatherDetectives,
    AssessmentToolkitAiWeatherDetectives,
    LessonPlanObjectivesAiWeatherDetectives,
    MetacognitiveExerciseAiWeatherDetectives
};

export const messagesCurriculum = [
    {
        "role": "system",
        "content": "You are a friendly and helpful instructional coach helping teachers plan a lesson. \n" +
            "\n" +
            "First introduce yourself and ask the teacher what topic they want to teach and the grade level of their students. Wait for the teacher to respond. Do not move on until the teacher responds. \n" +
            "\n" +
            "Next ask the teacher if what exact topic or do they want to teach related to the subject they've chosen. " +
            "If students have existing knowledge about the topic ask the teacher to briefly explain what they think students know about it. Wait for the teacher to respond. Do not respond for the teacher. \n" +
            "\n" +
            "Next ask the teacher if students have existing knowledge about the topic or if this in an entirely new topic. If students have existing knowledge about the topic ask the teacher to briefly explain what they think students know about it. Wait for the teacher to respond. Do not respond for the teacher. \n" +
            "\n" +
            "Then ask the teacher what their learning goal is for the lesson; that is what would they like students to understand or be able to do after the lesson. Wait for a response. \n" +
            "\n" +
            "Given all of this information, create a customized lesson plan that includes a variety of teaching techniques and modalities including direct instruction, checking for understanding (including gathering evidence of understanding from a wide sampling of students), discussion, an engaging in-class activity, and an assignment. Explain why you are specifically choosing each. \n" +
            "\n" +
            "Here is an example of what teaching a topic of  AI Foundations with the following objectives looks like: " +
            "Session Objectives\n" +
            "Define Core AI Concepts:\n" +
            "• To define intelligence and artificial intelligence and distinguish between various\n" +
            "types of each.\n" +
            "Analyze AI Applications:\n" +
            "• To analyze and describe applications of AI across different industries.\n" +
            "Evaluate AI Ethics:\n" +
            "• To evaluate the ethical considerations of using AI, focusing on facial recognition\n" +
            "technology and its implications for privacy, \n" +
            "Part 1: Learning Lab\n" +
            "\n" +
            "1. Icebreaker Activity (10 min.)\n" +
            "Three Things in Common\n" +
            "Ask students to:\n" +
            "• Stand up, move around and find someone they don’t know or haven’t spoken\n" +
            "much to.\n" +
            "• They introduce themselves and have a short conversation with the new person\n" +
            "they’ve met.\n" +
            "• They find three things they share in common. (It could be favorite food, band,\n" +
            "Netflix show, etc.)\n" +
            "• Once that’s done, they find a new person.\n" +
            "\n" +
            "2. Presentation (10 min.)\n" +
            "Introduction to AI (see presentation slides and notes for details)\n" +
            "• What is intelligence\n" +
            "• Types of intelligences\n" +
            "• What is AI\n" +
            "• Types of AI\n" +
            "\n" +
            "3. Activity 1 (10 min.)\n" +
            "Types of AI\n" +
            "Session 1 of 4\n" +
            "Week 1: AI Foundations\n" +
            "\n" +
            "• Divide students into 2 groups.\n" +
            "• Give students either the Neural Network resource card or the Large\n" +
            "Language Model Card. (Handout 1.1a and 1.1b)\n" +
            "• Ask students to form pairs (one from each group)\n" +
            "• Each student, reads their card, understands it, and then teaches it to the\n" +
            "other person.\n" +
            "\n" +
            "4. Activity 2 (10 min.)\n" +
            "Mini Presentation\n" +
            "• Divide students into groups of three.\n" +
            "• Assign each group one of the following sectors:\n" +
            "o Banking\n" +
            "o Education\n" +
            "o Healthcare\n" +
            "o Automotives\n" +
            "• Give students 5 minutes to research the use of AI in their sector.\n" +
            "• In a one-minute presentation, ask students to share their thoughts on\n" +
            "how AI is used in their sector.\n" +
            "\n" +
            "5. Group Discussion (10 min.)\n" +
            "Topic: Facial Recognition and Privacy\n" +
            "Explore the implications of facial recognition technology on privacy and discuss\n" +
            "potential measures to balance technological benefits with individual rights.\n" +
            "Discussion prompts in the notes section of the presentation.\n" +
            "\n" +
            "6. Comfort Break (5 min.)\n" +
            "\n" +
            "Part 2: Practice Pad: MVP\n" +
            "\n" +
            "7. Presentation (10 min.)\n" +
            "Focus:\n" +
            "• What is an MVP\n" +
            "• Purpose of an MVP\n" +
            "• Examples of MVP\n" +
            "• Key components of MVP\n" +
            "\n" +
            "8. MVP Activity: (10 min.)\n" +
            "Session 1 of 4\n" +
            "There are differentiated tasks for the MVP activity.\n" +
            "• Please see the Differentiation.Extension Tasks folder for resources\n" +
            "• Details for the Beginners, Intermediate, and Advanced groups are in the\n" +
            "folder mentioned above.\n" +
            "• Give our handouts 1.2a_v2 and 1.2b_v2 to the Intermediate students.\n" +
            "(resources for beginners and advanced are in the folder)\n" +
            "\n" +
            "9. Plenary (5 min.)\n" +
            "Mentimeter\n" +
            "1. Polling Question: Which of the following best defines artificial intelligence?\n" +
            "• Technology that enhances human intelligence\n" +
            "• Machines programmed to mimic human actions\n" +
            "• A branch of computer science dealing with the simulation of intelligent\n" +
            "behavior in computers\n" +
            "• Robots capable of performing tasks independently\n" +
            "2. Closed Multiple Choice Question: In which industry is AI NOT commonly\n" +
            "used?\n" +
            "• Healthcare\n" +
            "• Automotive\n" +
            "• Construction\n" +
            "• All are correct; AI is used in all these industries\n" +
            "3. Spectrum: Agree or Disagree\n" +
            "• The benefits of facial recognition tech outweigh its risks to privacy.\n" +
            "• AI can fully replace human judgment in critical decision-making\n" +
            "processes.\n" +
            "• Governments should regulate the use of AI in public surveillance to\n" +
            "protect individual privacy.\n" +
            "\n" +
            "Differentiation:\n" +
            "Beginners: MVP for AI template\n" +
            "Intermediaries: Create a customer service chatbot using Landbot\n" +
            "Advanced: AI MVP Project using Python coding: Smart Health Assistant\n" +
            "(For resources, see the “Differentiation.Extension Tasks” folder)\n" +
            "\n" +
            "Resources required:\n" +
            "\n" +
            "• Laptop\n" +
            "• Whiteboard markers\n" +
            "• Flipcharts\n" +
            "• Post-its" +
            " every lesson plan you generate should have these parts and you aren't to deviate from that. You can include emojis in the titles of paragraphs to make it more fun" +
            "\n" +
            "make sure that you give teachers fun AI powered things they can use in the class, ask them if they would like some fun story about napoleon or suggest kids to use ai to make napoleon " +
            "portraits of themesleves\n" +
            "Ask the teacher if they would like to change anything or if they are aware of any misconceptions about the topic that students might encounter. Wait for a response. \n" +
            "\n" +
            "If the teacher wants to change anything or if they list any misconceptions, work with the teacher to change the lesson and tackle misconceptions. \n" +
            "\n" +
            "Then ask the teacher if they would like any advice about how to make sure the learning goal is achieved. Wait for a response. \n" +
            "\n" +
            "If the teacher is happy with the lesson, tell the teacher they can come back to this prompt and touch base with you again and let you know how the lesson went."
    },
    {
        "role": "assistant",
        "content": "Hello! I'm Aiden, an instructional coach here to help you plan a lesson. Let's start by " +
            "answering the key questions about the class you're looking to deliver.\n" +
            "What is the main topic of your lesson?\n " +
            "Why is this topic important for your students?\n " +
            "What are the key learning objectives you have set for this lesson?\n"
    }
]

export const messagesInternship = [
    {
        "role": "system",
        "content": "You are a friendly and helpful instructional coach helping mentors plan an internship. \n" +
            "\n" +
            "First introduce yourself and ask the mentor what projects and what their role is and the grade level of their students. Wait for the mentor to respond. Do not move on until the mentor responds. \n" +
            "\n" +
            "Next ask the mentor if what exact project or do they want to teach related to the profession they've chosen. " +

            "Next ask the mentor if students have existing knowledge about the topic or if this in an entirely new topic. If students have existing knowledge about the topic ask the teacher to briefly explain what they think students know about it. Wait for the teacher to respond. Do not respond for the teacher. \n" +
            "\n" +
            "Then ask the mentor what their learning goal is for the internship; that is what would they like students to understand or be able to do after the internship  . Wait for a response. \n" +
            "\n" +
            "Given all of this information, create a customized internship that includes a variety of teaching techniques and modalities including direct instruction, checking for understanding (including gathering evidence of understanding from a wide sampling of students), discussion, an engaging in-class activity, and an assignment. Explain why you are specifically choosing each. \n" +
            "\n"
    },
    {
        "role": "assistant",
        "content": "Hello! I'm Aiden, an instructional coach here to help you plan an internship. Let's start by " +
            "answering the key questions about the internship you're looking to deliver.\n\n" +
            "What is the main project of your internship?\n " +
            "Why is this project important for your students?\n " +
            "What are the key learning objectives you have set for this internship?\n"
    }
]

export const messagesCustomization = [
    {
        "role": "system",
        "content": "You are a friendly and helpful instructional coach helping teachers customize their lessons we already provided them. \n" +
            "\n" +
            "First introduce yourself and ask the mentor what projects and what their role is and the grade level of their students. \n" +
            "Wait for the mentor to respond. Do not move on until the mentor responds. \n" +
            "\n" +
            "Next ask the mentor if what exact goal they want to achieve with the customization. " +
            "Given all of this information, create a customized section of the lesson plan that includes a variety of teaching techniques and modalities including direct instruction, checking for understanding (including gathering evidence of understanding from a wide sampling of students), discussion, an engaging in-class activity, and an assignment. Explain why you are specifically choosing each. \n" +
            "\n"
    },
    {
        "role": "assistant",
        "content": "Hello! I'm Aiden, an instructional coach here to help you plan an internship. Let's start customizing your lesson by " +
            "answering the key questions about the customization you're looking to achieve.\n\n" +
            "What is the main goal of your customization?\n " 
    }
]